import {Button, Checkbox, Form, Input} from "antd";
import React from "react";
import "./LoginForm.css";

function LoginComponent({onFinish, loadBtn, labelForm, form, changePass}) {
    const onFill = () => {
        form.setFieldsValue({
            login: "Test10@gmail.com",
            password: "LichiTest123",
        });
    };

    return (
        <div className="login-container">
            <Form
                form={form}
                layout="vertical"
                name="normal_login"
                className="login-form"
                initialValues={{
                    remember: true,
                }}
                onFinish={onFinish || changePass}
            >
                <div className="form-h1">{labelForm.h1}</div>
                <Form.Item
                    hidden={labelForm.inputLogin.hidden}
                    name={labelForm.inputLogin.name}
                    label={labelForm.inputLogin.label}
                    initialValue={labelForm.inputLogin.initialValue}
                >
                    <Input placeholder={labelForm.inputLogin.placeholder}/>
                </Form.Item>
                <Form.Item
                    name={labelForm.inputPass.name}
                    label={labelForm.inputPass.label}
                    hidden={labelForm.inputPass.hidden}
                    rules={[
                        {
                            message: labelForm.inputPass.message,
                        },
                    ]}
                >
                    <Input.Password/>
                </Form.Item>
                <Form.Item
                    name={labelForm.inputNewPass.name}
                    label={labelForm.inputNewPass.label}
                    hidden={labelForm.inputNewPass.hidden}
                    rules={[
                        {
                            message: labelForm.inputNewPass.message,
                        },
                    ]}
                >
                    <Input.Password/>
                </Form.Item>
                <Form.Item
                    name={labelForm.inputPassRepeat.name}
                    label={labelForm.inputPassRepeat.label}
                    hidden={labelForm.inputPassRepeat.hidden}
                    rules={[
                        {
                            required: true,
                            message: labelForm.inputPassRepeat.message,
                        },
                        ({getFieldValue}) => ({
                            validator(_, value) {
                                if (!value || getFieldValue("newPass") === value) {
                                    return Promise.resolve();
                                }

                                return Promise.reject(new Error("Пароли не совпадают!"));
                            },
                        }),
                    ]}
                >
                    <Input.Password/>
                </Form.Item>

                <Form.Item>
                    <Button onClick={onFill} hidden={labelForm.fillBtn.hidden}>
                        Заполнить
                    </Button>
                    <Button
                        type="primary"
                        htmlType="submit"
                        className="login-form-button"
                        loading={loadBtn}
                    >
                        {labelForm.finishBtn.text}
                    </Button>
                </Form.Item>
            </Form>
        </div>
    );
}

export default LoginComponent;
