import React, {useContext, useState} from "react";
// import "antd/dist/antd.css";
import {Menu, Button, Space, Dropdown, Modal} from "antd";
import {PageHeader} from '@ant-design/pro-layout';
import "./header.css";
import "../../fonts/fa-pro/css/all.min.css";
import {apiClient} from "../../tools/api";
import {authContext} from "../../tools/authContext";
import ModalPass from "../hooks/ModalPass/ModalPass";

export default function HeadMenu({visibleForm}) {
    const user = localStorage.getItem("user" || "no name");
    const {checkAuth} = useContext(authContext);
    const [collapsed, setCollapsed] = useState(false);

    const toggleCollapsed = () => {
        setCollapsed(!collapsed);
    };
    const [visible, setVisible] = useState(false);
    const logOut = async function () {
        apiClient.post("logout", {}).then((res) => {
            localStorage.removeItem("TOKEN_USER");
            console.log("logOut");
            checkAuth();
            localStorage.setItem("TableName", "goods");
            localStorage.setItem("current", 1);
        });
    };

    const getPass = async function () {
        apiClient.post("password/Test10@gmail.com", {}).then((res) => {
            console.log("getPass res", res);
        });
    };

    const visiblePassForm = (bool) => {
        setVisible(bool);
    };
    const settingsMenuItem = [
        {
            icon: <i className="fal fa-key"></i>,
            key: "Lk",
            label: "Изменить пароль",
            onClick: () => setVisible(true),
        },
        {
            key: "logOut",
            label: "Выйти",
            icon: <i className="fal fa-sign-out"></i>,
            onClick: logOut,
        },
    ];
    const menu = <Menu key="dropDownMenu" items={settingsMenuItem}/>;

    return (
        <div>
            <PageHeader
                ghost={false}
                style={{alignItems: "center"}}
                // onBack={() => window.history.back()}
                className="page-header"
                subTitle={
                    <Button
                        type="link"
                        className="btn-add"
                        onClick={() => {
                            visibleForm(true);
                        }}
                    >
                        <i className="fal fa-layer-plus" style={{color: '#fff'}}></i>
                        <span style={{marginLeft: "10px", color: 'rgba(255, 255, 255, 0.65)'}}>Добавить</span>
                    </Button>
                }
                extra={[
                    <div key="dropDownMenu" className="right-menu">
                        <div className="avatar">
                            <span key="22">{user}</span>
                        </div>
                        <div className="dropDown">
                            <Dropdown overlay={menu} trigger={["click"]}>
                                <a onClick={(e) => e.preventDefault()}>
                                    <Space>
                                        <Button
                                            type="primary"
                                            shape="circle"
                                            onClick={toggleCollapsed}
                                        >
                                            {collapsed ? (
                                                <i className="fal fa-user"></i>
                                            ) : (
                                                <i className="fal fa-user"></i>
                                            )}
                                        </Button>
                                    </Space>
                                </a>
                            </Dropdown>
                        </div>
                    </div>,
                ]}
            />
            <Modal
                open={visible}
                onOk={() => visiblePassForm(false)}
                onCancel={() => visiblePassForm(false)}
                footer={null}
                className="personal-area"
            >
                <ModalPass/>
            </Modal>
        </div>
    );
}
