import React, {useState} from "react";
import {Image, Layout, Menu} from "antd";
import "../../fonts/fa-pro/css/all.min.css";
import "./nav-menu.css";

const {Sider} = Layout;

export default function NavMenu({changeTable, tableName}) {
  let [collapsed, letCollapse] = useState(true);
  const settingsMenuItem = [
    {
      icon: <i className="fal fa-tshirt"></i>,
      key: "goods",
      label: "Товары",
      onClick: () => {
        changeTable("goods");
      },
    },
    {
      icon: <i className="fal fa-truck-loading"></i>,
      key: "supply",
      label: "Поставки",
      onClick: () => {
        changeTable("supply");
      },
    },
  ];
  return (
    <>
      <Sider
        collapsible
        collapsed={collapsed}
        onCollapse={() => letCollapse((collapsed = !collapsed))}
      >
        <Image
          className="logoImg"
          key="2"
          preview={false}
          src={
            collapsed
              ? "https://cdn3.lichi.com/site-content-static/ef717a52812cac9f45a0e16f9e270f4e/628784f11ca8a.png"
              : "https://cdn3.lichi.com/site-content-static/ef717a52812cac9f45a0e16f9e270f4e/628780c05c89b.png"
          }
        />
        <Menu
          selectedKeys={[tableName]}
          mode="inline"
          collapsed="false"
          theme="dark"
          items={settingsMenuItem}
        />
      </Sider>
    </>
  );
}
