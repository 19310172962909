import React, {useState} from "react";
import {Form, Input, Button} from "antd";
import {LockOutlined} from "@ant-design/icons";
import "./ModalPass.css";
import LoginComponent from "../LoginForm/loginForm";
import {apiClient} from "../../../tools/api";

export default function ModalPass() {
    const [form2] = Form.useForm();
    let [loadBtn, SetLoadBtn] = useState(false);

    const labelForm = {
        name: "changePass",
        h1: "Сменить пароль",
        hidden: false,
        fillBtn: {
            hidden: true,
        },
        finishBtn: {
            text: "Сохранить",
        },
        inputLogin: {
            hidden: true,
            initialValue: "",
            name: "login",
            message: "Логин!",
            label: "Логин",
        },
        inputPass: {
            hidden: false,
            name: "oldPass",
            label: "Пароль",
            message: "Введите пароль",
        },
        inputNewPass: {
            hidden: false,
            name: "newPass",
            label: "Новый пароль",
            message: "Введите пароль",
        },
        inputPassRepeat: {
            hidden: false,
            name: "newPassRepeat",
            label: "Подтверждение пароля",
            message: "Введите пароль",
        },
    };

    const changePass = async function ({oldPass, newPass, newPassRepeat}) {
        SetLoadBtn(true);
        apiClient.get("/sanctum/csrf-cookie").then((res) => {
            apiClient
                .post(
                    "password/change",
                    {
                        current_password: oldPass,
                        new_password: newPass,
                        new_confirm_password: newPassRepeat,
                    },
                    {}
                )
                .then((res) => {
                    console.log("Pass res", res);
                    SetLoadBtn(false);
                })
                .catch((err) => {
                    console.log("Ошибка", err);
                    SetLoadBtn(false);
                });
        });
    };

    return (
        <LoginComponent
            form={form2}
            loadBtn={loadBtn}
            labelForm={labelForm}
            changePass={changePass}
        />
    );
}
