import React, { useEffect, useLayoutEffect, useMemo, useState } from "react";
// import "antd/dist/antd.css";
import "./table.css";
import { apiClient } from "../../../tools/api";
import { Button, Drawer, Table } from "antd";
import FormComponent from "../../FormComponent/FormComponent";
import "../../../fonts/fa-pro/css/all.min.css";
import moment from "moment";
import GetDataTable from "../../hooks/getDataTable";

export default function TableComponent({ tableName, visibleForm, boolForm }) {

  const sorterObj ={
    sorter: true,
    defaultSortOrder: 'ascend',
    sortDirections: ['ascend', 'descend', 'ascend'],
  }
  const goods = [
    {
      title: "Фото",
      dataIndex: "img",

      key: "img",
      render: (data) => {
        return <img className="img" src={data} alt="" />;
      },
    },
    {
      title: "Создан",
      dataIndex: "created",
      sorter: true,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (data) => {
        return moment(data).format("DD.MM.YYYY H:mm");
      },
    },
    {
      title: "Категория",
      dataIndex: "category",
      key: "category",
      sorter: true,
      defaultSortOrder: 'ascend',
      sortDirections: ['ascend', 'descend', 'ascend'],
    },
    {
      title: "Цвет",
      dataIndex: "color",
      key: "color",
      sorter: true,
      defaultSortOrder: 'ascend',
      sortDirections: ['ascend', 'descend', 'ascend'],
    },
    {
      title: "Модель",
      dataIndex: "model",
      key: "model",
      sorter: true,
      defaultSortOrder: 'ascend',
      sortDirections: ['ascend', 'descend', 'ascend'],
    },
    {
      title: "Статус",
      dataIndex: "status",
      key: "status",
      sorter: true,
      defaultSortOrder: 'ascend',
      sortDirections: ['ascend', 'descend', 'ascend'],
    },
    {
      title: "Действие",
      dataIndex: "action",
      key: "action",
      render: (data, record) => {
        if (record.status === "Черновик") {
          return (
            <>
              <Button
                type="text"
                htmlType="submit"
                onClick={() => [setIdEdit(record.id), visibleForm(true)]}
                icon={<i className="fal fa-edit"></i>}
                danger
                // type="text"
              ></Button>
              <Button
                type="text"
                htmlType="submit"
                onClick={() => onDel(record.id)}
                icon={<i className="fal fa-trash"></i>}
                danger
                // type="text"
              ></Button>
            </>
          );
        } else {
          return (
            <>
              <Button
                type="text"
                htmlType="submit"
                onClick={() => [setIdEdit(record.id), visibleForm(true)]}
                icon={<i className="fal fa-edit"></i>}
                // type="text"
                disabled
              ></Button>
              <Button
                type="text"
                htmlType="submit"
                onClick={() => onDel(record.id)}
                icon={<i className="fal fa-trash"></i>}
                // type="text"
                disabled
              ></Button>
            </>
          );
        }
      },
    },
  ];
  const supply = [
    {
      title: "Наименование",
      dataIndex: "name",
      key: "name",
      sorter: true,
      defaultSortOrder: 'ascend',
      sortDirections: ['ascend', 'descend', 'ascend'],
    },
    {
      title: "Комментарий",
      dataIndex: "comment",
      key: "comment",
    },
    {
      title: "Создан",
      dataIndex: "created_at",
      key: "created_at",
      sorter: true,
      defaultSortOrder: 'ascend',
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (data) => {
        return moment(data).format("DD.MM.YYYY H:mm");
      },
    },

    {
      title: "Статус",
      dataIndex: "status",
      key: "status",
      sorter: true,
      defaultSortOrder: 'ascend',
      sortDirections: ['ascend', 'descend', 'ascend'],
    },
    {
      title: "Действие",
      dataIndex: "action",
      key: "action",
      render: (data, record) => {
        if (record.status === "Черновик") {
          return (
            <>
              <Button
                type="text"
                htmlType="submit"
                onClick={() => [setIdEdit(record.id), visibleForm(true)]}
                icon={<i className="fal fa-edit"></i>}
                danger
                // disabled
                // type="text"
              ></Button>
              <Button
                type="text"
                htmlType="submit"
                onClick={() => onDel(record.id)}
                icon={<i className="fal fa-trash"></i>}
                danger
                // type="text"
              ></Button>
            </>
          );
        } else {
          return (
            <>
              <Button
                type="text"
                htmlType="submit"
                onClick={() => [setIdEdit(record.id), visibleForm(true)]}
                icon={<i className="fal fa-edit"></i>}
                // type="text"
                disabled
              ></Button>
              <Button
                type="text"
                htmlType="submit"
                onClick={() => onDel(record.id)}
                icon={<i className="fal fa-trash"></i>}
                // type="text"
                disabled
              ></Button>
            </>
          );
        }
      },
    },
  ];
  const [idEdit, setIdEdit] = useState();
  const [formLabel, setFormLabel] = useState("Товары");
  const [column, setColumn] = useState(() => {
    if (tableName === "goods" || tableName === "") {
      return goods;
    } else if (tableName === "supply") {
      return supply;
    }
  });
  const {
    loading,
    dataTable,
    getData,
    pagination,
    SetPagination,
  } = GetDataTable(tableName, goods, supply);

  const changeTableData = () => {
    SetPagination({
      ...pagination,
      current: 1,
    });
    if (tableName === "goods") {
      setColumn(goods);
      setFormLabel(`Товары`);
      localStorage.setItem("TableName", "goods");
    } else if (tableName === "supply") {
      setColumn(supply);
      setFormLabel(`Поставки`);
      localStorage.setItem("TableName", "supply");
    }
    getData();
  };
  const onDel = (id) => {
    console.log("deleteFunc:", id);
    apiClient
      .delete(`/api/${tableName}/${id}`)
      .then((res) => {
        getData();
      })
      .catch((err) => {
        console.log("del err:", err);
      });
  };
  const onChange = (page, filters, sorter) => {
    SetPagination({
      ...page,
    });
    localStorage.setItem("current", page.current);
    getData(null, page, sorter.order);
  };
  const closeForm = () => {
    visibleForm(false);
    setIdEdit(-1);
    getData();
  };

  // useEffect(() => {
  //   console.log("useEffect getData");
  //   getData();
  // }, []);

  useEffect(() => {
    // console.log("useEffect changeTable");
    changeTableData();
  }, [tableName]);

  return (
    <>
      <Drawer
        title={formLabel}
        width={650}
        maskClosable={false}
        onClose={closeForm}
        open={boolForm}
        bodyStyle={{ paddingBottom: 80 }}
      >
        <FormComponent
          idEdit={idEdit}
          visible={boolForm}
          tableName={tableName}
          closeForm={closeForm}
          onClose={() => visibleForm(false)}
        />
      </Drawer>
      <Table
        columns={column}
        sortDirections={[`ascend`, `descend`]}
        rowKey={dataTable.key}
        dataSource={dataTable}
        pagination={pagination}
        loading={loading}
        onChange={onChange}
      />
    </>
  );
}
