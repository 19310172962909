import React, {useEffect, useState} from "react";
import {Button, Form, Input, InputNumber, Select, Tabs} from "antd";
import {apiClient} from "../../tools/api";
import {PlusOutlined, DeleteOutlined, DeleteTwoTone, LoadingOutlined} from "@ant-design/icons";
import {Spin, Alert} from 'antd';
import Space from "antd/es/space";
import requestComponent from './request';
import "./form.css";

import {Upload, Modal} from "antd";

const Option = Select.Option;
const {TabPane} = Tabs;


export default function FormComponent({
                                          visible,
                                          tableName,
                                          closeForm,
                                          idEdit,
                                      }) {
    const [form] = Form.useForm();
    let [dataForm, setDataForm] = useState([]);
    const size_id = dataForm["supply_goods[][size_id]"];
    const goods_id = dataForm["supply_goods[][goods_id]"];
    const [previewVisible, setPreviewVisible] = useState(false);
    const [previewImage, setPreviewImage] = useState();
    let [btnLabel, setBtnLabel] = useState('Добавить');
    const {good_Request, supply_Request} = requestComponent();
    let [loadForm, setLoadForm] = useState(false);
    let excludedItems = [];
    let percentForm = 0;
    let childrenMaterial = [];
    const [errPercent, setErrPercent] = useState(false)
    let [typeGoods, setTypeGoods] = useState([]);
    let [fileList, setFileList] = useState([]);
    const clearForm = () => {
        setLoadForm(false)
        form.resetFields();
        setFileList([]);
    };

    const addForm = () => {
        setBtnLabel('Добавить')
        // setBtnLabel( 'Добавить')
        apiClient
            .get(`api/get-form/${tableName}`, {})
            .then((res) => {
                // получаем данные для списка категории Состав (Материалы, Подкладка, Нполнитель)
                if (tableName === "goods") {
                    const typeGoods = res.data["compositions[][material_type_id]"].items.map(
                        (item) => {
                            return item;
                        }
                    );
                    setTypeGoods((typeGoods));
                }
                setDataForm((dataForm = res.data));
                setLoadForm(true)
            })
            .catch((error) => console.log('err addForm:', error));
    };

    const onEdit = (id) => {
        addForm();
        setBtnLabel('Редактировать')
        const imgPath = "https://supply-api-dev.spb.lichishop.com/storage/";
        if (tableName === "goods") {
            apiClient
                .get(`api/goods/${id}`)
                .then((res) => {
                    const arr = res.data.compositions;
                    const compositions = {Материалы: [], Подкладка: [], Наполнитель: []};
                    for (let j = 0; j < Object.keys(compositions).length; j++) {
                        let name = Object.keys(compositions)[j];
                        for (let i = 0; i < arr.length; i++) {
                            if (arr[i].material_type_id === j + 1) {
                                compositions[name].push(arr[i]);
                            }
                        }
                    }

                    const editItem = {
                        category_id: res.data.category_id,
                        color_id: res.data.color_id,
                        gost: res.data.gost,
                        model: res.data.model,
                        tnved: res.data.tnved,
                        photos: res.data.photos,
                        compositions,
                    };
                    // добавляем полученные фото в список
                    const newArrPhoto = editItem.photos.map(item => {
                        return {
                            uid: `-${item.id}`,
                            name: `image ${item.id}`,
                            status: "done",
                            url: imgPath + item.path,
                        };
                    });

                    setFileList(newArrPhoto);
                    form.setFieldsValue(editItem);
                    setLoadForm(true)
                })
                .catch((err) => [console.log("err onEdit goods", err), setLoadForm(false)]);
        } else if (tableName === "supply") {
            apiClient
                .get(`api/supply/${id}`)
                .then((res) => {
                    let editItem = {
                        name: res.data.name,
                        comment: res.data.comment,
                        supply_goods: res.data.supply_goods,
                    };

                    form.setFieldsValue(editItem);
                    setLoadForm(true)
                })
                .catch((err) => [console.log("err onEdit supply", err), setLoadForm(false)]);
        }
    };

    function getBase64(file) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
        });
    }

    const handlePreview = async (file) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }
        setPreviewImage(file.url || file.preview);
        setPreviewVisible(true);
    };

    const getCategoryId = (idGoods) => {
        let idRet = 0;
        goods_id.items.forEach(item => {
            if (item.id === idGoods) {
                idRet = item.category_id - 2
            }
        })
        return idRet
    };

    function onFinish(data) {
        if (tableName === 'goods' && percentForm !== 100) {
            // setErrPercent(true)
            alert('Процентное соотношение состава не равно 100')
            return;
        } else {
            setErrPercent(false)
        }
        if (tableName === "goods") {
            good_Request(data, idEdit, closeForm, tableName, fileList)
        } else if (tableName === "supply") {
            supply_Request(data, idEdit, closeForm, tableName, fileList)
        }
    }

    useEffect(() => {
        if (visible === false) {
            clearForm();
        } else if (visible === true) {
            if (typeof idEdit !== "number" || idEdit === -1) {
                addForm();
            } else {
                onEdit(idEdit);
            }
        }
        return () => {
            clearForm()
        }
    }, [visible]);


    const insertForm = () => {
        form.setFieldsValue({
            "model": "Таня",
            "category_id": 6,
            "color_id": 9,
            "tnved": "тест",
            "gost": "ТЕст",
            "compositions": {
                "Материалы": [
                    {
                        "percent": 12,
                        "material_type_id": 1,
                        "material_id": {
                            "label": "Полиэстер",
                            "value": 1,
                            "key": "Полиэстер"
                        }
                    },
                    {
                        "material_type_id": 1,
                        "material_id": {
                            "label": "Акрил",
                            "value": 4,
                            "key": "Акрил"
                        },
                        "percent": 32
                    }
                ],
                "Подкладка": [
                    {
                        "percent": 12,
                        "material_type_id": 2,
                        "material_id": {
                            "label": "Эластан",
                            "value": 2,
                            "key": "Эластан"
                        }
                    },
                    {
                        "material_type_id": 2,
                        "material_id": {
                            "label": "Акрил",
                            "value": 4,
                            "key": "Акрил"
                        },
                        "percent": 1
                    },
                    {
                        "material_type_id": 2,
                        "material_id": {
                            "label": "Полиэстер",
                            "value": 1,
                            "key": "Полиэстер"
                        },
                        "percent": 12
                    }
                ],
                "Наполнитель": [
                    {
                        "percent": 12,
                        "material_type_id": 3,
                        "material_id": {
                            "label": "Экокожа",
                            "value": 8,
                            "key": "Экокожа"
                        }
                    }
                ]
            }
        })
    }

    const handleCancel = () => setPreviewVisible(false);

    const handleChange = ({fileList}) => {
        if (
            fileList.length > 1 &&
            fileList[0].url ===
            "https://supply-api-dev.spb.lichishop.com/storage/images/no-image.jpg"
        ) {
            fileList.splice(0, 1);
        }
        setFileList(fileList);
    };


    const RequestPhoto = (data) => {
        const [, setProgress] = useState(0);
        const {onSuccess, onProgress} = data;
        // let config = {
        //     // headers: {
        //     //   "Content-Type": "multipart/form-data",
        //     // },
        //     onUploadProgress: (event) => {
        //         const percent = Math.floor((event.loaded / event.total) * 100);
        //         setProgress(percent);
        //         if (percent === 100) {
        //             setTimeout(() => setProgress(0), 1000);
        //         }
        //         onProgress({percent: (event.loaded / event.total) * 100});
        //     },
        // };
        onSuccess("Ok");
    };

    const removePhoto = (file) => {
        let id = Math.abs(file.uid);
        if (file.hasOwnProperty("url")) {
            apiClient
                .delete(`api/photo/${id}`)
                .catch((err) => console.log(err));
        }

    };

    const onFieldsChanges = (changedFields, allFields) => {
        excludedItems = [];
        percentForm = 0;
        setErrPercent(false)

        allFields.forEach(item => {
            if (item.name.length === 2) {
                let includeArr = []
                let filterArr = [];
                let percentArr = [];
                item.value.forEach(itemValue => {
                    if (itemValue?.material_id) {

                        if (typeof itemValue.percent === 'number') {
                            percentArr.push(itemValue.percent);
                        }
                        if (typeof itemValue.material_id === 'number') {
                            includeArr.push(itemValue.material_id)
                        } else {
                            includeArr.push(itemValue.material_id.value)
                        }

                    }
                })
                filterArr = childrenMaterial.filter((item, id) => !includeArr.includes(item.props.value))
                excludedItems.push(filterArr)
                percentArr.forEach(el => {
                    percentForm = percentForm + el
                })
            }
        })
    }

    const ItemsForm = ({
                           material = false,
                           text = false,
                           select = false,
                           photo = false,
                       }) => {
        return Object.values(dataForm).map((item, index) => {
            let children = [];
            let i = tableName === "supply" ? 1 : 0;
            if (item.description === 'Материал' && childrenMaterial.length === 0) {
                for (i; i < item.items.length; i++) {
                    childrenMaterial.push(
                        <Option key={item.items[i].id} value={item.items[i].id} label={item.items[i].name}>
                            {item.items[i].name}
                        </Option>
                    );
                }
            } else {
                for (i; i < item.items.length; i++) {
                    children.push(
                        <Option key={item.items[i].id} value={item.items[i].id} label={item.items[i].name}>
                            {item.items[i].name}
                        </Option>
                    );
                }
            }

            const Material = () => {
                const updateArr = () => {
                    form.setFieldsValue({})
                }

                return (
                    <>
                        <Tabs>
                            {typeGoods.map((item, key) => {
                                return (
                                    <TabPane
                                        forceRender
                                        key={item.id}
                                        tab={<span>{item.name}</span>}
                                    >
                                        <Form.List
                                            name={["compositions", item.name]}
                                            key='supply_goods'
                                            initialValue={[{percent: null}]}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Поля не заполнены'
                                                },
                                            ]}
                                        >
                                            {(fields, {add, remove}) => {
                                                return (
                                                    <div>
                                                        {fields.map((field, index) => (
                                                            <div key={field.key}>
                                                                <Form.Item
                                                                    name={[index, "material_type_id"]}
                                                                    initialValue={item.id}
                                                                    hidden={true}
                                                                >
                                                                    <InputNumber placeholder="Тип"/>
                                                                </Form.Item>
                                                                <Space className="material-space">
                                                                    <Button
                                                                        type="danger"
                                                                        className="dynamic-delete-button"
                                                                        onClick={() => remove(index)}
                                                                        icon={<DeleteTwoTone/>}
                                                                    />

                                                                    <Form.Item name={[index, "material_id"]}
                                                                               rules={[
                                                                                   {
                                                                                       required: true,
                                                                                       message: ''
                                                                                   },
                                                                               ]}
                                                                    >
                                                                        <Select placeholder="Состав"
                                                                                name="selectItem"
                                                                                onDropdownVisibleChange={updateArr}
                                                                                labelInValue
                                                                        >
                                                                            {excludedItems.length === 0 ? childrenMaterial :
                                                                                item.name === 'Материалы' ? excludedItems[0] :
                                                                                    item.name === 'Подкладка' ? excludedItems[1] :
                                                                                        item.name === 'Наполнитель' ? excludedItems[2] : null
                                                                            }

                                                                        </Select>
                                                                    </Form.Item>
                                                                    <Form.Item
                                                                        name={[index, "percent"]}
                                                                        rules={[
                                                                            {
                                                                                type: "number",
                                                                                min: 1,
                                                                                max: 100,
                                                                                message: 'от 1 до 100',
                                                                            },
                                                                            {
                                                                                required: true,
                                                                                message: '',
                                                                            },
                                                                        ]}
                                                                    >
                                                                        <InputNumber
                                                                            placeholder="%"
                                                                        />
                                                                    </Form.Item>
                                                                </Space>
                                                            </div>
                                                        ))}
                                                        <Form.Item>
                                                            <Button
                                                                type="dashed"
                                                                onClick={() => add()}
                                                                style={{width: "100%"}}
                                                            >
                                                                <PlusOutlined/> Добавить
                                                            </Button>
                                                        </Form.Item>
                                                    </div>
                                                );
                                            }}
                                        </Form.List>
                                    </TabPane>
                                );
                            })}
                        </Tabs>
                    </>
                );
            };

            const SupplyForm = () => {
                return (
                    <Form.List name="supply_goods" key='supply_goods'>
                        {(fields, {add, remove}) => (
                            <>
                                {fields.map((field, index, data) => (
                                    <Space
                                        key={field.key}
                                        className="space_container"
                                        align="baseline"
                                    >
                                        <Button
                                            type="danger"
                                            className="dynamic-delete-button"
                                            onClick={() => remove(field.name)}
                                            icon={<DeleteOutlined/>}
                                        />
                                        <Form.Item
                                            name={[field.name, "goods_id"]}
                                            className="input_goods"
                                            style={{minWidth: '300px'}}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: ''
                                                },
                                            ]}
                                        >
                                            <Select placeholder="Товар"
                                            >
                                                {Object.values(goods_id.items).map((item, i) => {
                                                    return <Option key={item.id} value={item.id}>{item.name}</Option>;
                                                })}
                                            </Select>
                                        </Form.Item>
                                        <Form.Item
                                            name={[index, "count"]}
                                            // rules={[{ required: true }]}
                                            rules={[
                                                {
                                                    type: "number",
                                                },
                                                {
                                                    required: true,
                                                    message: ''
                                                },
                                            ]}
                                        >
                                            <InputNumber placeholder="Кол-во"/>
                                        </Form.Item>

                                        <Form.Item shouldUpdate className="input_size">
                                            {({getFieldValue}) =>
                                                getFieldValue(["supply_goods", index, "goods_id"]) ? (
                                                    <Form.Item
                                                        name={[field.name, "size_id"]}
                                                        rules={[
                                                            {
                                                                type: "select",
                                                            },
                                                            {
                                                                required: true,
                                                                message: ''
                                                            },
                                                        ]}
                                                    >
                                                        <Select placeholder="">
                                                            {Object.values(size_id.items.category_id).map(
                                                                (arr, id) => {
                                                                    let idGoods = parseInt(
                                                                        getFieldValue([
                                                                            "supply_goods",
                                                                            index,
                                                                            "goods_id",
                                                                        ])
                                                                    );
                                                                    let Id = getCategoryId(idGoods, arr);
                                                                    if (id === Id) {
                                                                        return arr.map((itemArr) => {
                                                                            return (
                                                                                <Option key={itemArr.id}
                                                                                        value={itemArr.id}>
                                                                                    {itemArr.name}
                                                                                </Option>
                                                                            );
                                                                        });
                                                                    }
                                                                }
                                                            )}
                                                        </Select>
                                                    </Form.Item>
                                                ) : (
                                                    <Input disabled/>
                                                )
                                            }
                                        </Form.Item>
                                    </Space>
                                ))}
                                <Form.Item>
                                    <Button
                                        type="dashed"
                                        onClick={() => add()}
                                        block
                                        icon={<PlusOutlined/>}
                                    >
                                        Добавить позицию
                                    </Button>
                                </Form.Item>
                            </>
                        )}
                    </Form.List>
                );
            };


            //supply
            if (item.name.includes("supply_goods[][goods_id]")) {
                return (
                    <div key={item.id + 1}>
                        <SupplyForm/>
                    </div>
                );
            }
            if (item.name.includes("supply_goods[]")) {
                return;
            }
            //Material
            if (material) {
                if (item.description === "Материал") {
                    return (
                        <div key={item.id + 1}>
                            <Material/>
                        </div>
                    );
                }
            }

            // поле Материал рендерится на главной вкладке, скрываем его (Материал во вкладке "Состав")
            else if (item.description === "Материал") {
                return;
            }
            if (item.description === "Вид" || item.description === "процент") {
                return;
            }
            if (text) {
                if (item.type === "text") {
                    return (
                        <Form.Item
                            key={index}
                            name={item.name}
                            label={item.description}
                            type={item.type}
                            rules={[
                                {
                                    required: true,
                                    message: ''
                                },
                            ]}
                        >
                            <Input type={item.type} placeholder={item.description}/>
                        </Form.Item>
                    );
                }
            } else if (item.type === "text") {
                return;
            }
            if (select) {
                if (item.type === "select") {
                    return (
                        <Form.Item
                            key={index}
                            name={item.name}
                            label={item.description}
                            rules={[
                                {
                                    required: true,
                                    message: ''
                                },
                            ]}
                        >
                            <Select
                                style={{width: "100%"}}
                                placeholder={item.description}
                            >
                                {children}
                            </Select>
                        </Form.Item>
                    );
                }
            }
        });
    };

    return (
        <div>
            {loadForm === false ? <Spin indicator={<LoadingOutlined
                    style={{
                        fontSize: 24,
                    }}
                    spin
                />}/> :
                <Form
                    onFinish={onFinish}
                    layout="vertical"
                    form={form}
                    name="provider"
                    className="add-form"
                    autoComplete="off"
                    onFieldsChange={onFieldsChanges}
                >
                    {tableName === "goods" ? (
                            <Tabs defaultActiveKey="1">
                                <TabPane tab="Описание" key="1">
                                    <ItemsForm text={true} select={true}
                                    />
                                </TabPane>
                                <TabPane tab="Состав" key="2" forceRender>
                                    <ItemsForm material={true}
                                    />
                                </TabPane>
                                <TabPane tab="Фото" key="3">
                                    <div key="photoContainer">
                                        <Upload
                                            listType="picture-card"
                                            fileList={fileList}
                                            beforeUpload={() => false}
                                            onPreview={handlePreview}
                                            onChange={handleChange}
                                            customRequest={RequestPhoto}
                                            onRemove={removePhoto}
                                        >
                                            {fileList.length >= 12 ?
                                                null :
                                                <div>
                                                    <PlusOutlined/>
                                                </div>}
                                        </Upload>
                                        <Modal
                                            open={previewVisible}
                                            onCancel={handleCancel}
                                            onOk={handleCancel}
                                            footer={null}
                                            title
                                        >
                                            <img alt="example" style={{width: '100%'}} src={previewImage}/>
                                        </Modal>
                                    </div>
                                </TabPane>
                            </Tabs>
                        ) :
                        <ItemsForm text={true} select={true}
                        />
                    }

                    <Form.Item className="btn-container" shouldUpdate>

                        {/*<Button type={errPercent === false ? 'primary' : 'disabled '} htmlType="submit" className="button-end"*/}
                        {/*>*/}
                        {/*    {btnLabel}*/}
                        {/*</Button>*/}
                        {() => (
                            <Button
                                className="button-end"
                                type="primary"
                                htmlType="submit"
                                // disabled={
                                //     !form.isFieldsTouched(true) ||
                                //     !!form.getFieldsError().filter(({ errors }) => errors.length).length
                                // }
                                // disabled={errPercent}
                            >
                                {btnLabel}
                            </Button>
                        )}
                    </Form.Item>


                </Form>
            }
            {/*{errPercent === true ? (*/}
            {/*    <Alert message="Процентное соотношение состава не равно 100" type="warning" showIcon/>*/}
            {/*) : null}*/}
        </div>
    )
}