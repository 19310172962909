import axios from "axios";
const baseURL = "https://supply-api-dev.spb.lichishop.com/";
const token = localStorage.getItem("TOKEN_USER") || "";
export const apiClient = axios.create({
  baseURL,
  withCredentials: true,
  headers: {
    Accept: "application/json",
    "content-type": "application/json",
    Authorization: `Bearer ${token}`,
  },
});
