import React, { useContext, useState } from "react";
// import "antd/dist/antd.css";
import { Layout } from "antd";
import TableComponent from "./Table/Table";
import HeadMenu from "../Header/Header";
import NavMenu from "../NavMenu/NavMenu";
import { authContext } from "../../tools/authContext";

const { Header, Content } = Layout;

export default function TablePage() {
  const { user } = useContext(authContext);
  // console.log("user", user);

  const [tableName, setTableName] = useState(
    localStorage.getItem("TableName") || "goods"
  );
  const [boolForm, SetFormVisible] = useState(false);

  const changeTable = (table) => {
    localStorage.setItem("current", 1);
    // console.log("val", table);
    setTableName(table);
  };
  const visibleForm = (bool) => {
    // console.log("bool", bool);
    SetFormVisible(bool);
  };


  return (
    <>
      <div>
        <Layout
          style={{ minHeight: "100vh", background: "rgb(251 251 251 / 18%)" }}
        >
          <NavMenu changeTable={changeTable} tableName={tableName} />
          <Layout style={{ marginLeft: 0 }}>
            <Header style={{ padding: 0, height: "auto" }}>
              <HeadMenu user={user} visibleForm={visibleForm} />
            </Header>
            <Content
              style={{ margin: "0", overflow: "initial", height: "auto" }}
            >
              <TableComponent
                tableName={tableName}
                visibleForm={visibleForm}
                boolForm={boolForm}
              />
            </Content>
          </Layout>
        </Layout>
      </div>
    </>
  );
}
