import React, {useEffect, useState} from "react";
import {authContext} from "../../tools/authContext";
import {Routes, Route, useNavigate} from "react-router-dom";

import {apiClient} from "../../tools/api";
import Err from "../404/404";
import LoginUser from "../Login/Login";
import TablePage from "../TablePage/TablePage";

const App = () => {


  const versionApp = `v: test`;
  let navigate = useNavigate();

  let [auth, setAuth] = useState(
    ''
  );

  const checkAuth = function () {
    const token = localStorage.getItem("TOKEN_USER") || "";
    // console.log("checkAuth func");
    // console.log('token', token)
    if (token !== "") {
      localStorage.setItem("auth", "true");
      setAuth(true);
      // console.log("checkAuth", token);
    } else {
      setAuth(false);
      // console.log("err checkAuth");
    }
  };

  let [user, setUser] = useState(localStorage.getItem("user") || "no name");
  const getUserFunc = function () {
    apiClient.get("api/user").then((res) => {
      localStorage.setItem("user", res.data.fio);
      setUser(res);
      console.log("getUserFunc", res);
    });
  };

  // const LoginFunc = () => {
  //   checkAuth(true);
  //   console.log("App LoginFunc");
  // };
  // const logoutFunc = () => {
  //   checkAuth(false);
  //   console.log("App logoutFunc");
  // };
  useEffect(() => {
    console.log(versionApp)
    console.log(`m -> 
    Test10@gmail.com
    l -> 
    LichiTest123`)
  }, [])

  useEffect(() => {

    if (auth !== null || '') {
      checkAuth();
    } else if (auth === false) {
      navigate("/login");
    } else if (auth === true) {
      if (user !== "no name") {
        navigate("/table");
      } else {
        getUserFunc();
      }
    }
  }, [auth, user]);

  const Router = ({auth}) => {
    if (auth === true) {
      return (
        <Routes>
          <Route path="/" element={<TablePage/>}/>
          <Route path="/login" element={<TablePage/>}/>
          <Route path="/table" element={<TablePage/>}/>
          <Route path="*" element={<Err/>}/>
        </Routes>
      );
    } else if (auth === false) {
      return (
        <Routes>
          <Route path="/" element={<LoginUser/>}/>
          <Route path="/login" element={<LoginUser/>}/>
          <Route path="/table" element={<LoginUser/>}/>
          <Route path="*" element={<Err/>}/>
        </Routes>
      );
    }
  };

  return (
    <authContext.Provider value={{auth, user, checkAuth}}>
      <header>
        <Router auth={auth}/>
      </header>
    </authContext.Provider>
  );
};

export default App;
