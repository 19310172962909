import {apiClient} from "../../tools/api";

export default function requestComponent() {

    function good_Request(data, idEdit, closeForm, tableName, fileList) {
        let config = {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        };
        // let sum = 0;
        let fd = new FormData();

        // Материалы: [{…}] --> 0: {percent: 0, material_id: 2}

        //собираем Композицию
        let newComp = Object.values(data.compositions).flat(2).map(item => {
            // sum += item.percent;
            // console.log('item', item)
            return item;
        });
        // if (sum !== 100) {
        //     alert("Процентное соотношение состава не = 100%");
        //     return;
        // }

        // полученные фото с сервера в json формате
        fileList.forEach((item) => {
            if (item.hasOwnProperty("url")) {
                // console.log("fileList  null", item);
                return;
            } else {
                // console.log("fileList hasOwnProperty -- загружаемые фото с компа", item);
                fd.append("file[]", item.originFileObj);
            }
        });

        for (const [key, value] of Object.entries(data)) {
            if (key !== "compositions") {
                fd.append(key, value);
            } else {
                const newVal = newComp.map(item => {
                    let NewObj = {
                        percent: item.percent,
                        material_type_id: item["material_type_id"],
                        material_id: typeof item["material_id"] === 'number' ? item["material_id"] : item["material_id"].value,
                    };
                    return NewObj;
                });
                // console.log('newVal', newVal)
                fd.append("compositions", JSON.stringify(newVal));
            }
        }


        // загруженные фото пользователем (type=file)
        let newPhoto = fileList.filter((item) => {
            if (item.url !== undefined && item.url) {
                return item;
            } else return;
        });
        fd.append("photos", JSON.stringify(newPhoto));


        // редактирование  = 1 добавление = -1
        if (idEdit > 0) {
            // console.log("idEdit", idEdit);
            fd.append("_method", "PUT");
            apiClient
                .post(`api/${tableName}/${idEdit}`, fd, config)
                .then((res) => {
                    closeForm("request");
                })
                .catch((err) => console.log(err));
        } else {
            apiClient
                .post(`api/goods`, fd, config)
                .then((res) => {
                    closeForm("request");
                })
                .catch((err) => console.log(err));
        }

    }

    function supply_Request(data, idEdit, closeForm, tableName) {
        let formData = new FormData();
        formData.append("data", data);

        if (idEdit > 0) {
            apiClient
                .put(`api/${tableName}/${idEdit}`, data)
                .then((res) => {
                    closeForm("request");
                })
                .catch((err) => console.log(err));
        } else {
            apiClient
                .post(`api/supply`, data)
                .then((res) => {
                    closeForm("request");
                })
                .catch((err) => console.log(err));
        }
    }

    return {
        good_Request,
        supply_Request
    }
}