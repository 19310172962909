import React, {useContext, useEffect, useState} from "react";
// import "antd/dist/antd.css";
import {apiClient} from "../../tools/api";
import {authContext} from "../../tools/authContext";
import LoginComponent from "../hooks/LoginForm/loginForm";
import "./index.css";
import {Form} from "antd";

export default function LoginUser() {
  const [form1] = Form.useForm();
  let [loadBtn, SetLoadBtn] = useState(false);
  const {checkAuth, auth} = useContext(authContext);
  const labelForm = {
    name: "logins",
    h1: "Авторизация",
    hidden: false,
    fillBtn: {
      hidden: false,
    },
    finishBtn: {
      text: "Войти",
    },
    ImgForm: "changePass",
    inputLogin: {
      hidden: false,
      name: "login",
      label: "Логин",
      message: "Please input your Login!",
      initialValue: "",
      placeholder: "",
    },
    inputPass: {
      name: "password",
      label: "Пароль",
      message: "Please input your Login!",
    },
    inputNewPass: {
      hidden: true,
    },
    inputPassRepeat: {
      hidden: true,
    },
  };

  const Login = async function (email, password) {
    SetLoadBtn(true);
    apiClient
      .get("/sanctum/csrf-cookie")
      .then((res) => {
        apiClient
          .post("/api/sanctum/token", {
            email,
            password,
            device_name: "android",
          })
          .then((res) => {
            console.log("res token", res);
            localStorage.setItem("TOKEN_USER", res.data);
            checkAuth();
          })
          .catch((err) => {
            console.log("err", err);
            if(err.response.data.message === 'The given data was invalid.') {
              alert("Указанные данные недействительны");
            }
          });
        SetLoadBtn(false);
      })
  };

  const onFinish = (value) => {
    console.log("value", value);
    Login(value.login, value.password);
    console.log("auth login", auth);

  };

  useEffect(() => {
    try {
      console.log('try')
    } catch (err) {
      console.log('err', err)
    }
  })

  //

  return (
    <LoginComponent
      onFinish={onFinish}
      form={form1}
      loadBtn={loadBtn}
      labelForm={labelForm}
    />
  );
}
