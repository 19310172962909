import { apiClient } from "../../tools/api";
import { useState } from "react";
import UseGetPagination from "./Pagination";

export default function GetDataTable(tableName) {
  const [loading, SetLoad] = useState(false);
  let [dataTable, SetDataTable] = useState([]);
  const imgPath = "https://supply-api-dev.spb.lichishop.com/storage";
  const { pagination, SetPagination, paginationFunc } = UseGetPagination();

  function GoodsData(data) {
    return {
      key: data.id,
      id: data.id,
      created: data["created_at"],
      category: data.category.name,
      color: data.color.name,
      model: data.model,
      status: data.status.title,
      article: "article",
      user_id: data.user_id,
      img:
        data && data.photos && data.photos.length === 0
          ? `https://st3.depositphotos.com/23594922/31822/v/600/depositphotos_318221368-stock-illustration-missing-picture-page-for-website.jpg`
          : `${imgPath}/${data.photos[0].path}`,
    };
  }

  function SupplyData(data) {
    return {
      key: data.id,
      id: data.id,
      comment: data.comment,
      name: data.name,
      created_at: data.created_at,
      status: data.status.title,
    };
  }

  function getData(startPage, page, sorter) {
    // console.log('getData')
    // console.log(`sorter = ${sorter}`);
    let currentPage =
      startPage || parseInt(localStorage.getItem("current")) || 1;
    const pageSize = pagination.pageSize;
    const lastPage = pagination.last;
    let sort;
    SetLoad(true);
    let field = `created`;
    if (sorter === "ascend") {
      sorter = "asc";
    } else if (sorter === "descend") {
      sorter = "desc";
    }
    if (sorter !== undefined && sorter !== "") {
      sort = `&${field}=${sorter}`;
    } else {
      sort = ``;
    }
    apiClient
      .get(
        `api/${tableName}/paginate/${pageSize}?page=${currentPage}${sort}`,
        {}
      )
      .then((res) => {
        // console.log("data get", res);
        let NewArray = res.data.data.map((data) => {
          if (tableName === "goods") {
            return GoodsData(data);
          } else if (tableName === "supply") {
            return SupplyData(data);
          }
        });
        paginationFunc(res, currentPage);
        SetDataTable(NewArray);
        SetLoad(false);
      })
      .catch((err) => console.log(err));
  }
  return {
    loading,
    pagination,
    SetPagination,
    dataTable,
    getData,
  };
}
