import { useState } from "react";

export default function UseGetPagination() {
  let [pagination,SetPagination] = useState({
    total: "",
    last: "",
    pageSize: 15,
  });

  function paginationFunc(res, currentPage) {
    let last = res.data["last_page"];

    let NewPagination = {
      ...pagination,
      current: currentPage,
      last,
      total: res.data["total"],
    };
    // console.log("NewPagination", NewPagination);
    SetPagination(NewPagination);
  }
  return {
    pagination,
    SetPagination,
    paginationFunc,
  };
}
